<app-message></app-message>
<app-loader></app-loader>
@if(currentUrl == '/login' || currentUrl=='/set-password'){
<router-outlet></router-outlet>
}@else {
<div class="main-wrapper">
  @if(showHeader){
  <div [className]="!isCollapsedMenu ? 'menu-collapse-wrapper' : 'menu-wrapper'" >
    <app-header (menuCollapse)="collapseEvent($event)"></app-header>
  </div>
  }
  <div class="pb-5" [className]="!isCollapsedMenu ? 'content-collapse-wrapper' : 'content-wrapper'" >
    <router-outlet></router-outlet>
  </div>
</div>
}