import { Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DashboardGuard } from './guards/dashboard.guard';
import { AuthGuard } from './guards/auth.guard';
import { ShowroomGuard } from './guards/showroom.guard';
import { SuperAdminGuard } from './guards/super-admin.guard';
import { CompanyAdminGuard } from './guards/company-admin.guard';
import { StockyardGuard } from './guards/stockyard.guard';
import { ServiceGuard } from './guards/service.guard';
import { BranchManagerGuard } from './guards/branch-manager.guard';
import { SearchGuard } from './guards/search.guard';
import { AuditTrialGuard } from './guards/audit-trial.guard';
import { UsedCarGuard } from './guards/used-car.guard';
import { GatePassGuard } from './guards/gate-pass.guard';

export const routes: Routes = [
   {
      path: '',
      pathMatch: 'full',
      redirectTo: 'dashboard'
   },
   {
      path: 'login',
      loadComponent: () => import('./components/admin-login/admin-login.component').then(comp => comp.AdminLoginComponent)
   },
   {
      path: 'set-password',
      loadComponent: () => import('./components/reset-password/reset-password.component').then(comp => comp.ResetPasswordComponent),
   },
   {
      path: 'dashboard',
      loadComponent: () => import('./components/dashboard/dashboard.component').then(comp => comp.DashboardComponent),
      canActivate: [AuthGuard, DashboardGuard]
   },
   {
      path: 'manage-company',
      loadComponent: () => import('./modules/companies/manage-company/manage-company.component').then(comp => comp.ManageCompanyComponent),
      canActivate: [AuthGuard, SuperAdminGuard]
   }, {
      path: 'add-company',
      loadComponent: () => import('./modules/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
      canActivate: [AuthGuard, SuperAdminGuard]
   },
   {
      path: "update-company/:companyId",
      loadComponent: () => import('./modules/companies/add-company/add-company.component').then(comp => comp.AddCompanyComponent),
      canActivate: [AuthGuard, SuperAdminGuard]
   },
   {
      path: 'manage-branch',
      loadComponent: () => import('./modules/branch/manage-branch/manage-branch.component').then(comp => comp.ManageBranchComponent),
      canActivate: [AuthGuard, CompanyAdminGuard]
   },
   {
      path: 'add-branch',
      loadComponent: () => import('./modules/branch/add-branch/add-branch.component').then(comp => comp.AddBranchComponent),
      canActivate: [AuthGuard, CompanyAdminGuard]
   },
   {
      path: "update-branch/:branchId",
      loadComponent: () => import('./modules/branch/add-branch/add-branch.component').then(comp => comp.AddBranchComponent),
      canActivate: [AuthGuard, CompanyAdminGuard]
   },
   {
      path: 'upload-internal-vehicle-details',
      loadComponent: () => import('./components/uploads/upload-demo-staff-vehicle/upload-demo-staff-vehicle.component').then(comp => comp.UploadDemoStaffVehicleComponent),
      canActivate: [AuthGuard, BranchManagerGuard]
   },
   {
      path: 'place-request',
      loadComponent: () => import('./components/place-vehicle-order/place-vehicle-order.component').then(comp => comp.PlaceOrderVehicleComponent),
      canActivate: [AuthGuard, ShowroomGuard]
   },
   {
      path: 'upload-stockyard-details',
      loadComponent: () => import('./components/uploads/upload-stockyard/upload-stockyard.component').then(comp => comp.UploadStockyardComponent),
      canActivate: [AuthGuard, StockyardGuard]
   },
   {
      path: 'upload-customer-vehicle-details',
      loadComponent: () => import('./components/uploads/upload-customer-vehicle/upload-customer-vehicle.component').then(comp => comp.CustomerVehicleComponent),
      canActivate: [AuthGuard, ServiceGuard]
   },
   {
      path: 'search-vehicle',
      loadComponent: () => import('./components/search-vehicle/search-vehicle.component').then(comp => comp.SearchVehicleComponent),
      canActivate: [AuthGuard, SearchGuard]
   },
   {
      path: 'manage-employees',
      loadComponent: () => import('./components/employee/manage-employees/manage-employees.component').then(comp => comp.ManageEmployeesComponent),
      canActivate: [AuthGuard, BranchManagerGuard]
   },
   {
      path: 'add-employee',
      loadComponent: () => import('./components/employee/add-employees/add-employees.component').then(comp => comp.AddEmployeesComponent),
      canActivate: [AuthGuard, BranchManagerGuard]
   },
   {
      path: 'update-employee/:employeeId',
      loadComponent: () => import('./components/employee/add-employees/add-employees.component').then(comp => comp.AddEmployeesComponent),
      canActivate: [AuthGuard, BranchManagerGuard]
   },
   {
      path: 'pending-requests',
      loadComponent: () => import('./components/pending-requests/pending-requests.component').then(comp => comp.PendingRequestsComponent),
      canActivate: [AuthGuard, StockyardGuard]
   },
   {
      path: 'audit-trial',
      loadComponent: () => import('./components/audit-trial/manage-audit-vehicles/manage-audit-vehicles.component').then(comp => comp.ManageAuditVehiclesComponent),
      canActivate: [AuthGuard, AuditTrialGuard]
   },
   {
      path: 'audit-trial/vehicle-detail/:checkInOutId',
      loadComponent: () => import('./components/audit-trial/audit-vehicle-details/audit-vehicle-details.component').then(comp => comp.AuditVehicleDetailsComponent),
      canActivate: [AuthGuard, AuditTrialGuard]
   },
   {
      path: 'manage-used-cars',
      loadComponent: () => import('./components/manage-used-cars/manage-used-cars.component').then(comp => comp.ManageUsedCarsComponent),
      canActivate: [AuthGuard, UsedCarGuard]
   }, {

      path: 'issue-gatepass',
      loadComponent: () => import('./modules/issue-gatepass/issue-gatepass/issue-gatepass.component').then(comp => comp.IssueGatepassComponent),
      canActivate: [AuthGuard, GatePassGuard]
   },
   {
      path: '404', component: PageNotFoundComponent
   },
   { path: '**', redirectTo: '404' }

];
