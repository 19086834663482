<div class="hamburger-menu d-flex d-md-none" (click)="onShowMenu()">
  <img src="../../../assets/images/right-arrow.png" class="menu-collapse-arrow w-100 align-self-center" loading="lazy"
    alt="Slide">
</div>
<nav class="navBar">
  <div>
    <div class="logo">
      <a class="text-center mb-0 font-32" [routerLink]="'/'"><img src="/assets/images/digigate-logo.png"
          class="logoImg w-100" alt="digigate"></a>
    </div>
    <ul class="navbar-nav px-2 px-md-4">
      @if(this.authService.isCompanyAdmin || this.authService.isBranchAdmin || this.authService.isGPEmployee ||
      this.authService.isOtherEmployee){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/dashboard">
        <img src="../../../assets/images/dashboard.png" title="Dashboard" loading="lazy" alt="Dashboard">
        <span>
          Dashboard
        </span>
      </li>
      }

      @if(this.authService.isGPEmployee || this.authService.isBranchAdmin){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/search-vehicle">
        <img src="../../../assets/images/searching.png" title="Search" loading="lazy" alt="Search">
        <span>
          Search
        </span>
      </li>}
      @if(this.authService.isBranchAdmin || this.authService.isCustomerServiceManager){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/audit-trial">
        <img src="../../../assets/images/audit-trial.png" title="Audit Trial" loading="lazy" alt="Audit trial">
        <span>
          Audit Trial
        </span>
      </li>
      }
      @if(this.authService.isGPEmployee){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/issue-gatepass">
        <img src="../../../assets/images/gate-pass.png" title="Issue Gatepass" loading="lazy" alt="Issue Gatepass">
        <span>
          Issue Gate Pass
        </span>
      </li>
      }

      @if(this.authService.isShowRoomManager){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/place-request">
        <img src="../../../assets/images/place-request.png" title="Place Request" loading="lazy" alt="Place Request">
        <span>
          Place Request
        </span>
      </li>
      }
      @else if(authService.isSuperAdmin){

      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/manage-company">
        <img src="../../../assets/images/manage-company.png" title="Manage Companies" loading="lazy"
          alt="Manage Companies">
        <span>
          Manage Companies
        </span>
      </li>
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/add-company">
        <img src="../../../assets/images/add-company.png" title="Add Company" loading="lazy" alt="Add Company">
        <span>
          Add Company
        </span>
      </li>
      }
      @else if(authService.isCompanyAdmin){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/manage-branch">
        <img src="../../../assets/images/manage-branch.png" title=" Manage Branch" loading="lazy" alt="Manage Branch">
        <span>
          Manage Branch
        </span>
      </li>
      }
      @else if(authService.isBranchAdmin){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/issue-gatepass">
        <img src="../../../assets/images/gate-pass.png" title="Issue Gatepass" loading="lazy" alt="Issue Gatepass">
        <span>
          Issue Gate Pass
        </span>
      </li>
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="/manage-employees">
        <img src="../../../assets/images/manage-employee.png" title="Manage Employee" loading="lazy"
          alt="Manage Employee">
        <span>
          Manage Employee
        </span>
      </li>
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="upload-internal-vehicle-details">
        <img src="../../../assets/images/staff-vehicle.png" title="Internal Vehicle Details" loading="lazy"
          alt="Internal Vehicle Details">
        <span>
          Internal Vehicle Details
        </span>
      </li>
      }
      @else if(authService.isStockYardManager){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="upload-stockyard-details">
        <img src="../../../assets/images/stockyard-vehicle.png" title="Stockyard Vehicle Details" loading="lazy"
          alt="Stockyard Vehicle">

        <span>
          Stockyard Vehicle Details
        </span>
      </li>
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="pending-requests">
        <img src="../../../assets/images/request.png" title="Pending Requests" loading="lazy" alt="Request">

        <span>
          Pending Requests
        </span>
      </li>
      }@else if(authService.isServiceCenterManager){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="upload-customer-vehicle-details">
        <img src="../../../assets/images/customer-vehicle.png" title="Customer Vehicle Details" loading="lazy"
          alt="Customer Vehicle">
        <span>
          Customer Vehicle Details
        </span>
      </li>
      }@else if(authService.isUsedCarManager){
      <li class="nav-item pointer" routerLinkActive="activeLink" routerLink="manage-used-cars">
        <img src="../../../assets/images/customer-vehicle.png" title="manage used cars" loading="lazy"
          alt="manage used cars">
        <span>
          Manage Used Cars
        </span>
      </li>
      }

    </ul>
  </div>
  <ul type="none" class="profile-info mt-5">
    <li>
      <div class="d-flex signed-in-user gap-2 align-items-center">
        <div>
          <img src="../assets/images/user-image.png" class="user-image" alt="avatar">
        </div>
        <div class="userId text-start w-100 d-md-block">
          <h6 class="mb-0 text-truncate" [title]="dataService.getLocalStorageItem('user_name')">{{dataService.getLocalStorageItem('user_name')}}</h6>
          <p class="font-10 text-truncate mb-0 fw-bold text-white text-start" [title]="authService.userType | replace:'_':' ' ">{{authService.userType | replace:'_':' ' }}
          </p>
        </div>
      </div>
    </li>
    <li class="nav-item pointer" (click)="onLogout()">
      <img src="../assets/images/logout.png" alt="logout" class="exitIcon">
      <span>
        Logout
      </span>
    </li>

  </ul>
</nav>



<!-- <app-modal #myProfile [modalConfig]="modalConfig">
  <div>

    <h4>My Profile</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
  </div>

</app-modal> -->